import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "role",
    "customerIdContainer",
    "customerFieldsContainer",
  ];

  connect() {
    this.customerShowHide();
  }

  customerShowHide() {
    if (this.hasRoleTarget) {
      if (this.roleTarget.value == "customer") {
        if (this.hasCustomerFieldsContainerTarget) {
          this.customerFieldsContainerTarget.classList.remove("d-none");
        }
        if (this.hasCustomerIdContainerTarget) {
          this.customerIdContainerTarget.classList.add("d-none");
        }
      } else if (this.roleTarget.value == "lab" || this.roleTarget.value == "clinician") {
        if (this.hasCustomerFieldsContainerTarget) {
          this.customerFieldsContainerTarget.classList.add("d-none");
        }
        if (this.hasCustomerIdContainerTarget) {
          this.customerIdContainerTarget.classList.remove("d-none");
        }
      } else {
        if (this.hasCustomerFieldsContainerTarget) {
          this.customerFieldsContainerTarget.classList.add("d-none");
        }
        if (this.hasCustomerIdContainerTarget) {
          this.customerIdContainerTarget.classList.add("d-none");
        }
      }
    }
  }
}
