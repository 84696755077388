import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Cropper from "cropperjs";

function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      if (this.dropZone.element.classList.contains("crop")) {
        if (file.cropped) {
          setTimeout(() => {
            file.accepted && createDirectUploadController(this, file).start();
          }, 500);
          return;
        }
        var ratio = 1;
        if (this.dropZone.element.classList.contains("crop-0-27")) {
          ratio = 1 / 0.27;
        }
        var myDropZone = this.dropZone;
        var controller = this;
        var newFile = file;
        myDropZone.removeFile(file);
        // Create the image editor overlay
        var modalContainer = document.createElement("div");
        modalContainer.classList.add("cropper-modal-container");
        document.body.appendChild(modalContainer);
        var editor = document.createElement("div");
        editor.classList.add("cropper-editor");
        modalContainer.appendChild(editor);
        // Create confirm button at the top left of the viewport
        var buttonConfirm = document.createElement("button");
        buttonConfirm.classList.add(
          "cropper-confirm",
          "btn",
          "btn-primary",
          "btn-lg"
        );
        buttonConfirm.textContent = "Confirm image resize";
        modalContainer.appendChild(buttonConfirm);
        buttonConfirm.addEventListener("click", function () {
          // Get the canvas with image data from Cropper.js
          var canvas = cropper.getCroppedCanvas({
            width: 1024,
            height: 1024,
          });
          // Turn the canvas into a Blob (file object without a name)
          canvas.toBlob(function (blob) {
            // Create a new Dropzone file thumbnail
            myDropZone.createThumbnail(
              blob,
              myDropZone.options.thumbnailWidth,
              myDropZone.options.thumbnailHeight,
              myDropZone.options.thumbnailMethod,
              false,
              function (dataURL) {
                // Update the Dropzone file thumbnail
                myDropZone.emit("thumbnail", newFile, dataURL);
                // Return the file to Dropzone
                // done(blob);
              }
            );
            document.body.removeChild(modalContainer);
            var file = blobToFile(blob, newFile.name);
            file.cropped = true;
            myDropZone.addFile(file);
          });
          // Remove the editor from the view
        });
        // Create an image node for Cropper.js
        var image = new Image();
        image.classList.add("cropper-image-element");
        image.src = URL.createObjectURL(newFile);
        editor.appendChild(image);

        // Create Cropper.js
        var cropper = new Cropper(image, {
          aspectRatio: ratio,
          responsive: true,
          modal: false,
        });
      } else {
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
        }, 500);
      }
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.setAttribute("activeText", element.value);
      element.value = "Uploading";
      element.disabled = true;
    });
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.value = element.getAttribute("activeText");
      element.disabled = false;
    });
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    document.querySelectorAll(`input[type="submit"]`).forEach((element) => {
      element.value = element.getAttribute("activeText");
      element.disabled = false;
    });
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
  });
}
