import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "navbar",
    "mainContent",
    "toggleButton",
    "hideToggle",
    "showToggle",
    "supplierCategoryContainer",
  ];

  connect() {
    const sidebarHidden = localStorage.getItem("sidebarHidden");
    if (sidebarHidden === "true") {
      this.autoHide();
    }
  }

  autoHide() {
    if (this.hasNavbarTarget) {
      this.navbarTarget.classList.add("sidebar-auto-hide");
    }
    if (this.hasMainContentTarget) {
      this.mainContentTarget.classList.add("main-content-expand");
    }
    if (this.hasHideToggleTarget) {
      this.hideToggleTarget.classList.add("d-none");
    }
    if (this.hasShowToggleTarget) {
      this.showToggleTarget.classList.remove("d-none");
    }
    localStorage.setItem("sidebarHidden", "true");
  }

  show() {
    if (this.hasNavbarTarget) {
      this.navbarTarget.classList.remove("sidebar-auto-hide");
    }
    if (this.hasMainContentTarget) {
      this.mainContentTarget.classList.remove("main-content-expand");
    }
    if (this.hasHideToggleTarget) {
      this.hideToggleTarget.classList.remove("d-none");
    }
    if (this.hasShowToggleTarget) {
      this.showToggleTarget.classList.add("d-none");
    }
    localStorage.setItem("sidebarHidden", "false");
  }
}
