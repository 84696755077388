import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "productQuantity",
    "productPrice",
    "totalPrice",
    "customerDiscount",
    "finalPrice",
  ];

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    let product_quantities = this.productQuantityTargets;
    let total = 0;
    let discount_percentage = 0;

    const highlightClass = "bg-warning-subtle";

    if (this.hasCustomerDiscountTarget) {
      discount_percentage = parseFloat(this.customerDiscountTarget.value);
    }
    product_quantities.forEach((product_quantity) => {
      const quantity = parseInt(product_quantity.value, 10);
      if (isNaN(quantity) || quantity < 0) {
        product_quantity.closest("tr").classList.remove(highlightClass);
        return;
      } else {
        if (quantity > 0) {
          product_quantity.closest("tr").classList.add(highlightClass);
        } else {
          product_quantity.closest("tr").classList.remove(highlightClass);
        }
      }
      const price = parseFloat(product_quantity.dataset.price);
      total += quantity * price;
    });

    const final_price = total * (1 - discount_percentage / 100);

    this.totalPriceTarget.innerHTML = total.toFixed(2);
    this.finalPriceTarget.innerHTML = final_price.toFixed(2);
  }
}
