import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "loading",
    "upperArch",
    "lowerArch",
    "upperArchContainer",
    "lowerArchContainer",
    "protocol",
    "estimatedDays",
    "upperProtocol",
    "lowerProtocol",
    "upperCredits",
    "lowerCredits",
    "upperFinalCredits",
    "lowerFinalCredits",
    "upperCustomerDiscount",
    "lowerCustomerDiscount",
    "upperEstimatedDays",
    "lowerEstimatedDays",
    "totalCredits",
    "customerDiscount",
    "customerDiscountValue",
    "customerDiscountContainer",
    "totalDiscountedCreditsContainer",
    "totalFinalCredits",
    "totalEstimatedDays",
    "customerId",
    "paymentTerms",
    "paymentTermsDisplay",
    "totalCreditsValue",
    "totalFinalCreditsValue",
    "totalEstimatedDaysValue",
    "urgency",
  ];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    this.changeCounter = 0;
    this.toggleArches();
    if (!this.hasProtocolTarget) {
      this.protocolChange();
      this.customerChange();
    }
  }

  toggleArches() {
    if (this.hasUpperArchContainerTarget) {
      let upperArchContainer = this.upperArchContainerTarget;
      if (this.upperArchTarget.checked) {
        upperArchContainer.classList.remove("d-none");
      } else {
        upperArchContainer.classList.add("d-none");
      }
    }
    if (this.hasLowerArchContainerTarget) {
      let lowerArchContainer = this.lowerArchContainerTarget;
      if (this.lowerArchTarget.checked) {
        lowerArchContainer.classList.remove("d-none");
      } else {
        lowerArchContainer.classList.add("d-none");
      }
    }
    this.protocolChange();
  }

  editProtocolChange(event) {
    if (this.changeCounter > 0) {
      this.protocolChange();
    } else {
      this.changeCounter += 1;
    }
  }

  protocolChange(event) {
    let totalCredits = 0.0;
    let totalFinalCredits = 0.0;
    let upperCredits = 0.0;
    let upperBasePrice = 0.0;
    let upperProstheticPrice = 0.0;
    let upperImplantPrice = 0.0;
    let upperFinalCredits = 0.0;
    let lowerCredits = 0.0;
    let lowerBasePrice = 0.0;
    let lowerProstheticPrice = 0.0;
    let lowerImplantPrice = 0.0;
    let lowerFinalCredits = 0.0;
    let basePrice = 0.0;
    let prostheticPrice = 0.0;
    let implantPrice = 0.0;
    let credits = 0.0;
    let finalCredits = 0.0;
    let upperEstimatedDays = 0;
    let lowerEstimatedDays = 0;
    let totalEstimatedDays = 0;
    let urgency = false;
    let urgencyPercentage = 0;
    let urgencyFee = 0;
    let urgencyDaysReductionPercentage = 0;
    if (this.hasUrgencyTarget) {
      urgency = this.urgencyTarget.checked;
      if (!isNaN(parseFloat(this.urgencyTarget.dataset.urgencyPercentage))) {
          urgencyPercentage = parseFloat(this.urgencyTarget.dataset.urgencyPercentage);
      }
      if (!isNaN(parseFloat(this.urgencyTarget.dataset.urgencyFee))) {
        urgencyFee = parseFloat(this.urgencyTarget.dataset.urgencyFee);
      }
      if (!isNaN(parseFloat(this.urgencyTarget.dataset.urgencyDaysReductionPercentage))) {
        urgencyDaysReductionPercentage = parseFloat(this.urgencyTarget.dataset.urgencyDaysReductionPercentage);
      }
    }
    if (this.hasUpperArchContainerTarget) {
      if (this.upperArchTarget.checked) {
        if (this.hasUpperProtocolTarget) {
          this.upperProtocolTargets.forEach((e) => {
            if (e.checked) {
              let upperProstheticCount = document.getElementById(
                "upper_prosthetic_count"
              ).value;
              let upperImplantCount = document.getElementById(
                "upper_implant_count"
              ).value;
              if (!isNaN(parseFloat(e.dataset.basePrice))) {
                upperBasePrice = parseFloat(e.dataset.basePrice);
              }
              if (
                !isNaN(parseFloat(e.dataset.prostheticPrice)) &&
                upperProstheticCount > 0
              ) {
                upperProstheticPrice =
                  parseFloat(e.dataset.prostheticPrice) * upperProstheticCount;
              }
              if (
                !isNaN(parseFloat(e.dataset.implantPrice)) &&
                upperImplantCount > 0
              ) {
                upperImplantPrice =
                  parseFloat(e.dataset.implantPrice) * upperImplantCount;
              }
              upperCredits =
                upperBasePrice + upperProstheticPrice + upperImplantPrice;

              if (urgency) {
                if (urgencyPercentage > 0) {
                  upperCredits += upperCredits * urgencyPercentage / 100;
                }
                if (urgencyFee > 0) {
                  upperCredits += urgencyFee;
                }
              }
              totalCredits += upperCredits;

              if (this.hasUpperCustomerDiscountTarget) {
                let upperCustomerDiscount =
                  this.upperCustomerDiscountTarget.value;
                if (
                  !isNaN(parseFloat(upperCustomerDiscount)) &&
                  upperCustomerDiscount > 0
                ) {
                  upperFinalCredits =
                    upperCredits * (1 - upperCustomerDiscount / 100);
                } else {
                  upperFinalCredits = upperCredits;
                }
              } else {
                upperFinalCredits = upperCredits;
              }
              this.upperFinalCreditsTarget.value = upperFinalCredits;
              totalFinalCredits += upperFinalCredits;

              if (this.hasUpperEstimatedDaysTarget) {
                upperEstimatedDays = parseInt(
                  this.upperEstimatedDaysTarget.value
                );
                if (urgency && urgencyDaysReductionPercentage > 0) {
                  upperEstimatedDays = parseInt(upperEstimatedDays - (upperEstimatedDays * urgencyDaysReductionPercentage / 100));
                }
                totalEstimatedDays += upperEstimatedDays;
              }
            }
          });
        }
        if (this.hasUpperCreditsTarget) {
          this.upperCreditsTarget.value = upperCredits;
        }
      }
    }
    if (this.hasLowerArchContainerTarget) {
      if (this.lowerArchTarget.checked) {
        if (this.hasLowerProtocolTarget) {
          this.lowerProtocolTargets.forEach((e) => {
            if (e.checked) {
              let lowerProstheticCount = document.getElementById(
                "lower_prosthetic_count"
              ).value;
              let lowerImplantCount = document.getElementById(
                "lower_implant_count"
              ).value;
              if (!isNaN(parseFloat(e.dataset.basePrice))) {
                lowerBasePrice = parseFloat(e.dataset.basePrice);
              }
              if (
                !isNaN(parseFloat(e.dataset.prostheticPrice)) &&
                lowerProstheticCount > 0
              ) {
                lowerProstheticPrice =
                  parseFloat(e.dataset.prostheticPrice) * lowerProstheticCount;
              }
              if (
                !isNaN(parseFloat(e.dataset.implantPrice)) &&
                lowerImplantCount > 0
              ) {
                lowerImplantPrice =
                  parseFloat(e.dataset.implantPrice) * lowerImplantCount;
              }
              lowerCredits =
                lowerBasePrice + lowerProstheticPrice + lowerImplantPrice;

              if (urgency) {
                if (urgencyPercentage > 0) {
                  lowerCredits += lowerCredits * urgencyPercentage / 100;
                }
                if (urgencyFee > 0) {
                  lowerCredits += urgencyFee;
                }
              }

              totalCredits += lowerCredits;

              if (this.hasLowerCustomerDiscountTarget) {
                let lowerCustomerDiscount =
                  this.lowerCustomerDiscountTarget.value;
                if (
                  !isNaN(parseFloat(lowerCustomerDiscount)) &&
                  lowerCustomerDiscount > 0
                ) {
                  lowerFinalCredits =
                    lowerCredits * (1 - lowerCustomerDiscount / 100);
                } else {
                  lowerFinalCredits = lowerCredits;
                }
              } else {
                lowerFinalCredits = lowerCredits;
              }
              this.lowerFinalCreditsTarget.value = lowerFinalCredits;
              totalFinalCredits += lowerFinalCredits;

              if (this.hasLowerEstimatedDaysTarget) {
                lowerEstimatedDays = parseInt(
                  this.lowerEstimatedDaysTarget.value
                );
                if (urgency && urgencyDaysReductionPercentage > 0) {
                  lowerEstimatedDays = parseInt(lowerEstimatedDays - (lowerEstimatedDays * urgencyDaysReductionPercentage / 100));
                }
                totalEstimatedDays += lowerEstimatedDays;
              }
            }
          });
        }
        if (this.hasLowerCreditsTarget) {
          this.lowerCreditsTarget.value = lowerCredits;
        }
      }
    }
    if (this.hasProtocolTarget) {
      this.protocolTargets.forEach((e) => {
        if (e.checked) {
          let prostheticCount =
            document.getElementsByClassName("prosthetic_count")[0].value;
          let implantCount =
            document.getElementsByClassName("implant_count")[0].value;
          if (!isNaN(parseFloat(e.dataset.basePrice))) {
            basePrice = parseFloat(e.dataset.basePrice);
          }
          if (
            !isNaN(parseFloat(e.dataset.prostheticPrice)) &&
            prostheticCount > 0
          ) {
            prostheticPrice =
              parseFloat(e.dataset.prostheticPrice) * prostheticCount;
          }
          if (!isNaN(parseFloat(e.dataset.implantPrice)) && implantCount > 0) {
            implantPrice = parseFloat(e.dataset.implantPrice) * implantCount;
          }
          credits = basePrice + prostheticPrice + implantPrice;
          if (urgency) {
            if (urgencyPercentage > 0) {
              credits += credits * urgencyPercentage / 100;
            }
            if (urgencyFee > 0) {
              credits += urgencyFee;
            }
          }
          totalCredits += credits;

          if (this.hasCustomerDiscountValueTarget) {
            let customerDiscount = this.customerDiscountValueTarget.value;
            if (!isNaN(parseFloat(customerDiscount)) && customerDiscount > 0) {
              finalCredits = credits * (1 - customerDiscount / 100);
            } else {
              finalCredits = credits;
            }
          } else {
            finalCredits = credits;
          }
          totalFinalCredits += finalCredits;

          if (this.hasUpperEstimatedDaysTarget) {
            upperEstimatedDays = parseInt(this.upperEstimatedDaysTarget.value);
            if (urgency && urgencyDaysReductionPercentage > 0) {
              upperEstimatedDays = parseInt(upperEstimatedDays - (upperEstimatedDays * urgencyDaysReductionPercentage / 100));
            }
            totalEstimatedDays += upperEstimatedDays;
          }
        }
      });
      this.totalCreditsValueTarget.value = totalCredits;
      this.totalFinalCreditsValueTarget.value = totalFinalCredits;
      this.totalEstimatedDaysValueTarget.value = totalEstimatedDays;
    }
    if (this.hasTotalCreditsTarget) {
      this.totalCreditsTarget.innerHTML = totalCredits;
    }
    if (this.hasTotalFinalCreditsTarget) {
      this.totalFinalCreditsTarget.innerHTML = totalFinalCredits;
    }
    if (this.hasTotalEstimatedDaysTarget) {
      this.totalEstimatedDaysTarget.innerHTML = totalEstimatedDays;
    }
  }

  async customerChange(event) {
    let value = this.customerIdTarget.value;

    if (value && value != "") {
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.remove("d-none");
      }

      const response = await get(`/admin/users/${value}.json`, {
        responseKind: "json",
      });

      if (response.ok) {
        const body = await response.json;
        let customerDiscount = body.discount_percentage;
        if (this.hasCustomerDiscountTarget) {
          this.customerDiscountTarget.innerHTML = customerDiscount;
        }
        if (this.hasCustomerDiscountValueTarget) {
          this.customerDiscountValueTarget.value = customerDiscount;
        }
        if (this.hasUpperCustomerDiscountTarget) {
          this.upperCustomerDiscountTarget.value = customerDiscount;
        }
        if (this.hasLowerCustomerDiscountTarget) {
          this.lowerCustomerDiscountTarget.value = customerDiscount;
        }
        if (customerDiscount > 0) {
          if (this.hasCustomerDiscountContainerTarget) {
            this.customerDiscountContainerTarget.classList.remove("d-none");
          }
          if (this.hasTotalDiscountedCreditsContainerTarget) {
            this.totalDiscountedCreditsContainerTarget.classList.remove(
              "d-none"
            );
          }
        } else {
          if (this.hasCustomerDiscountContainerTarget) {
            this.customerDiscountContainerTarget.classList.add("d-none");
          }
          if (this.hasTotalDiscountedCreditsContainerTarget) {
            this.totalDiscountedCreditsContainerTarget.classList.add("d-none");
          }
        }
        if (this.hasPaymentTermsTarget) {
          if (body.payment_terms != "" && body.payment_terms != null) {
            this.paymentTermsTarget.value = body.payment_terms;
            this.paymentTermsDisplayTarget.innerHTML = body.payment_terms;
          } else {
            const settingsResponse = await get(`/settings/1.json`, {
              responseKind: "json",
            });
            const settingsBody = await settingsResponse.json;
            let defaultPaymentTerms = settingsBody.fields.default_payment_terms;

            this.paymentTermsTarget.value = defaultPaymentTerms;
            this.paymentTermsDisplayTarget.innerHTML = defaultPaymentTerms;
          }

        }

        let clinicianElement = document.getElementById("clinician-id-select");
        if (clinicianElement) {
          let options = clinicianElement.options;
          for (let i = 0; i < options.length; i++) {
            if (options[i].dataset.role == 'customer') {
              if (options[i].dataset.userId == body.id.toString()) {
                options[i].style.display = '';
              } else {
                options[i].style.display = 'none';
              }
            }
          }
        }
        this.protocolChange();
      }

      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none");
      }
    }
  }
}
