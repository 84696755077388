import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
const _ = require("lodash");

export default class extends Controller {
  connect() {
    this.element.addEventListener("keyup", this.handleChange);
  }
  handleChange(event) {
    if (event.key == ",") {
      event.target.value = event.target.value.replace(",", ".");
    }
    // event.target.value = formatted;
  }
}
