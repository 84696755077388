import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["value"];
  static values = {
    submitUrl: String,
  };

  connect() {

  }

  async submit(event) {
    event.preventDefault();
    event.stopPropagation();

    let value = this.valueTarget.value;
    let field = this.valueTarget.dataset.field;
    let objectId = this.valueTarget.dataset.objectId;
    let frameId = this.valueTarget.dataset.frameId;
    let framePartial = this.valueTarget.dataset.framePartial;

    const response = await post(this.submitUrlValue, {
      body: JSON.stringify({
        value: value,
        frame_id: frameId,
        frame_partial: framePartial,
        id: objectId,
        field: field,
      }),
      responseKind: "turbo-stream",
    });
  }
}
